import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Data from './data';
import Pagination from './pagination';
import {  Link } from 'react-router-dom';
import Menu from './menu';


class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            currentPage: 1,
            postsPerPage: 10,
            loading: true,
            status: 0,
            btnallPosts: false,
            btnBeers: false,
            btnBreweries: false,
            btnBars: false,
        };

        

    }


  

    componentDidMount() {

        const allUrl = Data.allPosts;
        
        fetch(allUrl)
          .then(data => data.json())
          .then(data => {
              this.setState({
                posts: data,
                loading: false,
                
              })
          })
          .catch(error => console.error(error));
        
         
    }

    
     

  render() {


    const posts = this.state.posts;
    const loading = this.state.loading;

    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


    const paginate = number => {
        this.setState({currentPage: number})
    }

    const menuTest = () => {

        this.setState({btnBeers: false, btnallPosts: true, btnBreweries: false, btnBars:false});

        const beerUrl = Data.allPosts;

            fetch(beerUrl)
            .then(data => data.json())
            .then(data => {
                this.setState({
                    posts: data,
                    loading: false
                })
            })
            .catch(error => console.error(error));
        
    }


    const menuTest2 = () => {

        this.setState({btnBeers: true, btnallPosts: false, btnBreweries: false, btnBars:false});
        
        const beerUrl = Data.beers;

            fetch(beerUrl)
            .then(data => data.json())
            .then(data => {
                this.setState({
                    posts: data,
                    loading: false
                })
            })
            .catch(error => console.error(error));

        
    }


    const menuTest3 = () => {

        this.setState({btnBeers: false, btnallPosts: false, btnBreweries: false, btnBars:true});

        const beerUrl = Data.bars;

            fetch(beerUrl)
            .then(data => data.json())
            .then(data => {
                this.setState({
                    posts: data,
                    loading: false
                })
            })
            .catch(error => console.error(error));
        
    }

    const menuTest4 = () => {

        this.setState({btnBeers: false, btnallPosts: false, btnBreweries: true, btnBars:false});
        
        const beerUrl = Data.breweries;

            fetch(beerUrl)
            .then(data => data.json())
            .then(data => {
                this.setState({
                    posts: data,
                    loading: false
                })
            })
            .catch(error => console.error(error));
        
    }

    
    const col1 = [],col2 = [];

    if(!loading){


        currentPosts.map((item, i) => {
            const imge = item._embedded['wp:featuredmedia']['0'].source_url;
            const id = item.id;
            const fi = item.featured_media;

            if((i % 2 === 0) === true){
                col1.push(
                        <div key={i} className="post">
                                
                                <Col md={8} sm={12} xs={12} className="img-post-responsive">
                                    <img className="post-image card-image" src={imge} alt="beers IPAs Brews"/>
                                </Col>
                                
                                <Col md={4} sm={12} xs={12}>
                                    <Link to={`post/${id}/${fi}`}>
                                        <h1 className="post-title">{item.title.rendered}</h1>
                                    </Link>

                                    <div dangerouslySetInnerHTML={{__html: item.excerpt.rendered}}></div>
                                    <div className="line-breaker"></div>
                                    
                                    <Link to={`post/${id}/${fi}`}>
                                        <div className="btn-read-more">
                                            <p className="text-btn">Read More +</p>
                                        </div>
                                    </Link>
                                </Col>
                        </div>
                    
                );
            } else{
                col2.push(
                    <div key={i} className="post post-reverse">
                            <Col md={4} sm={12} xs={12}>
                                <Link to={`post/${id}/${fi}`}>
                                    <h1 className="post-title">{item.title.rendered}</h1>
                                </Link>

                                <div dangerouslySetInnerHTML={{__html: item.excerpt.rendered}}></div>
                                <div className="line-breaker"></div>
                                    
                                <Link to={`post/${id}/${fi}`}>
                                    <div className="btn-read-more">
                                        <p className="text-btn">Read More +</p>
                                    </div>
                                </Link>
                            </Col>

                            
                            <Col md={8} sm={12} xs={12} className="post-left img-post-responsive">
                                <img className="post-image card-image" src={imge} alt="beers IPAs Brews"/>
                            </Col>
                            
                    </div>
                   
                );
            }
        })

    } else {
        return <div className="loader-box">
                    <img className="loader" src={require('../img/loader.gif')} alt="loader" />
                </div>
                     
    }

    return (
        <div>

            <Col sm={12} xs={12}>
                <Menu 
                    menuTest={menuTest} 
                    menuTest2={menuTest2}  
                    menuTest3={menuTest3} 
                    menuTest4={menuTest4} 
                    btnallPosts={this.state.btnallPosts}  
                    btnBeers={this.state.btnBeers}
                    btnBreweries={this.state.btnBreweries}
                    btnBars={this.state.btnBars}
                />
            </Col>
            <Col sm={12} xs={12}>
                {col1}
            </Col>
            <Col sm={12} xs={12}>
                {col2}
            </Col>
            <Col sm={12} xs={12}>
                <Pagination cp={this.state.currentPage} paginate={paginate} posts={posts.length} postsPerPage={this.state.postsPerPage}/>
            </Col>
            
        </div>
        
    );
  }

}

export default Posts;
