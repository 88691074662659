import React, { Component } from 'react';
import {  Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';
import Footer from './footer';

class Us extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowIn: false,
        };
    }
    

  render() {

    /*<a style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@timothycdykes?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Timothy Dykes">
                <span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32">
                    <title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span>
                <span style="display:inline-block;padding:2px 3px">Timothy Dykes</span>
            </a>*/


        window.scrollTo(0, 0);


    return (


        <div>

            <Col className="back-btn" xs={12} sm={6} md={6}>
              <Link to={`/`}>
                <img className="back-post" src={require('../img/getmeback.png')} alt="back" />
              </Link>
              <Link to={`/`}>
                <p className="take-me-back">take me back</p>
              </Link>
            </Col>

            <Container className="us-container">
                <Row>
                    <Col md={12} sm={12} xs={12}>
                        <div className="container-us-foto">
                            <img className="us-foto" src={require('../img/us.jpg')} alt="loader" />
                        </div>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <div className="container-us-description">
                            <p className="us-description">
                                Hello, my name is Raul Rincones Izaguirre, I'm a Web Developer and a Beer lover. 
                                I developed this website to <span className="honor-us"> give praise to</span> honor every independent brewery around the world. please feel free to send me an email
                            </p>
                        </div>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <div className="container-us-email">
                            <h1 className="us-email">
                                hello@sonsofthebrew.com
                            </h1>
                        </div>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <div className="container-us-tech">
                            <p className="us-tech">
                                <a className="a-us-react" href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">
                                    This web was developed with React
                                </a> 
                            </p>
                        </div>
                    </Col>
                    <Col md={12} sm={12} xs={12}>
                        <div className="container-us-social">
                            <p className="us-social">
                                <a className="a-us-social" href="https://www.linkedin.com/in/raulrinconesizaguirre/" rel="noopener noreferrer" target="_blank">
                                    + Linkedin
                                </a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>


            <Footer />

        </div>

        
        
      
    )
  }

}

export default Us;
