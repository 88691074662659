import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Us from './us';


class Footer extends Component {
    

  render() {
      

    return (
       
        <div className="footer-menu">
            <div>
                <a className="footer-ig" href="https://www.instagram.com/sonsofthebrew/" rel="noopener noreferrer" target="_blank">
                    Instagram
                </a>
            </div>
            <div className="footer-tittle">
                Sons of the Brew 2020
            </div>
            
            <Link to={'/us'}>
                <div className="footer-us">
                    About us
                </div>                        
            </Link>
        </div>
    );
  }

}

export default Footer;
