import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';
import Header from './header';
import Posts from './posts';
import Footer from './footer';

class Us extends Component {
    
    

  render() {

    

    return (

        <div>
        
            <Header />

            <Container>
                <Row>
                    <Col sm={12} xs={12}>
                        <Posts />
                    </Col>
                </Row>
            </Container>
                
            <Footer />
            
        </div>
        
      
    )
  }

}

export default Us;
