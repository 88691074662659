import React, { Component } from 'react';


class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumbers: []
        }
    }


  render() {
      
    const posts = this.props.posts;
    const postsPerPage = this.props.postsPerPage;
    const calcPosts = Math.ceil(posts / postsPerPage);


    for(let i = 1; i <= calcPosts; i++) {
        if (this.state.pageNumbers.includes(i) === false) this.state.pageNumbers.push(i);        
    }

    const numbersPagination = this.state.pageNumbers;
    const paginate = this.props.paginate;

    //console.log(this.props.cp);

    return (
        <nav>
            <ul className="pagination">
                {numbersPagination.map((number, index) => (

                        number === this.props.cp 
                        
                        ? 

                        <li key={index} className="page-item">
                            <div onClick={() => paginate(number)} className="btn-active">
                               <p>{number}</p> 
                            </div>
                        </li>

                        :

                        <li key={index} className="page-item">
                            <div onClick={() => paginate(number)} className="btn-pagination">
                                <p>{number}</p>
                            </div>
                        </li>

                    
                ))}
            </ul>
        </nav>
    );
  }

}

export default Posts;
