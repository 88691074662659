import React, { Component } from 'react';
import Anime from 'react-anime';
import { Col } from 'react-bootstrap';
import '../App.css';


class Header extends Component {


  render() {

    const styleHeader = {
        padding: 0,
    }

    
    let show;


        show = <div className="App">
                    {/*<Anime easing="easeInOutCubic" translateX={['-150em', '0em']} delay={(e, i) => i * 100}>
                        <Col sm={12} xs={12} style={styleHeader}>
                            <header className="App-header">
                                <Col sm={8} xs={8}>
                                    <div className="text-hb">
                                        <Anime easing="easeOutElastic"
                                            duration={1000}
                                            direction="alternate"
                                            delay={(el, index) => index * 240}
                                            translateX={['0', '15em']}
                                            opacity={[1, 0]}
                                            scale={[.75, .9]}>
                                            <h1>Welcome to</h1>
                                            <Anime easing="easeInOutCubic" translateX={['-150em', '0em']} delay={(e, i) => i * 10000} duration={1000}>
                                                <div className="line1"></div>
                                            </Anime>
                                            <h1>Sons of the</h1>
                                            <Anime easing="easeInOutCubic" translateX={['-150em', '0em']} delay={(e, i) => i * 10} duration={10}>
                                                <div className="line1"></div>
                                            </Anime>
                                            <h1>BREW</h1>
                                            <Anime easing="easeInOutCubic" translateX={['-150em', '0em']} delay={(e, i) => i * 10000} duration={200}>
                                                <div className="line1"></div>
                                            </Anime>
                                        </Anime>
                                    </div>
                                </Col>
                            </header>
                        </Col>
                    </Anime>*/}

                        <Col sm={12} xs={12} style={styleHeader}>
                            <header className="App-header">
                                <Col sm={8} xs={8}>
                                    <div className="logo-container">
                                    <img className="logo" width="20%" src={require('../img/logo.png')} alt="logo" />
                                    </div>
                                </Col>
                            </header>
                        </Col>
            
                
                    <Anime easing="easeOutElastic"
                        duration={1000}
                        direction="alternate"
                        loop={true}
                        delay={(el, index) => index * 240}
                        translateY='1rem'
                        >

                        <Col sm={12} xs={12} >
                            <h1 className="scroll-down-text">
                                Scroll Down
                            </h1>
                        </Col>
                    </Anime>
                </div>;

    
    
    return (

        <div>
            {show}
        </div>
      
    )
  }

}

export default Header;


