import React, { Component } from 'react';
import Anime from 'react-anime';
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowIn: false,
        };
    }
    

  render() {

    //const show = this.props.showing;
    let show = true;
    let showing;
    

    const menuTest = this.props.menuTest;
    const menuTest2 = this.props.menuTest2;
    const menuTest3 = this.props.menuTest3;
    const menuTest4 = this.props.menuTest4;

    const btnBeers = this.props.btnBeers;
    const btnBars = this.props.btnBars;
    const btnBreweries = this.props.btnBreweries;
    const btnallPosts = this.props.btnallPosts;

    const btnAllStylo = document.querySelector('#btnAll');
    const btnBeersStylo = document.querySelector('#btnBeers');
    const btnBarsStylo = document.querySelector('#btnBars');
    const btnBreweriesStylo = document.querySelector('#btnBreweriesId');


    if( btnallPosts === true){
        btnAllStylo.classList.add("menu-active");
        btnBeersStylo.classList.remove("menu-active");
        btnBarsStylo.classList.remove("menu-active");
        btnBreweriesStylo.classList.remove("menu-active");
    } else if( btnBeers === true){
        btnBeersStylo.classList.add("menu-active");
        btnBarsStylo.classList.remove("menu-active");
        btnBreweriesStylo.classList.remove("menu-active");
        btnAllStylo.classList.remove("menu-active");
    } else if( btnBars === true){
        btnBarsStylo.classList.add("menu-active");
        btnBreweriesStylo.classList.remove("menu-active");
        btnAllStylo.classList.remove("menu-active");
        btnBeersStylo.classList.remove("menu-active");
    } else if( btnBreweries === true){
        btnBreweriesStylo.classList.add("menu-active");
        btnBarsStylo.classList.remove("menu-active");
        btnAllStylo.classList.remove("menu-active");
        btnBeersStylo.classList.remove("menu-active");
    }


    if(show){
        showing = <Anime easing="easeInOutCubic" translateX={['-150em', '0em']} delay={(e, i) => i * 100}>
            <Container>
                <Row>
                    <Col md={3} sm={3}></Col>
                    <Col md={6} xs={12} sm={6} className="div-menu">
                        <div onClick={() => menuTest()} >
                            <div className="btn menu-btn" id="btnAll"><span>All</span></div>
                        </div>
                        <div onClick={() => menuTest2()} >
                            <div className="btn menu-btn" id="btnBeers">Beers</div>
                        </div>
                        <div onClick={() => menuTest4()} >
                            <div className="btn menu-btn" id="btnBreweriesId">Breweries</div>
                        </div>
                        <div onClick={() => menuTest3()} >
                            <div className="btn menu-btn" id="btnBars">Bars</div>
                        </div>
                    </Col>
                    <Col md={3} sm={3}></Col>
                </Row>
            </Container>
            
        </Anime>;
    } else {
        return null;
    }


    return (

        <div className="Menu">
            {showing}
        </div>
      
    )
  }

}

export default Menu;
