import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Footer from './footer';


class PostDetails extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        item: {},
        image: ''
    }
  }


  handleExit = () =>{
    this.props.history.goBack();
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    let postUrl = `https://afxjlkijqw.sonsofthebrew.com/wp-json/wp/v2/posts/${id}`;
    fetch(postUrl)
      .then(data => data.json())
      .then(data => {
          this.setState({
             item: data
          })
      })
      .catch(error => console.error(error));

      

      const img = this.props.match.params.fi;
      let imgUrl = `https://afxjlkijqw.sonsofthebrew.com/wp-json/wp/v2/media/${img}`;
      fetch(imgUrl)
        .then(res => res.json())
        .then(res => {
            this.setState({
               image: res.source_url
            })
        })
        .catch(error => console.error(error));
  }

  

 
  render() {

    const imageUrl = this.state.image;
    const post = this.state.item;


    //BREWERY ACF
    let brewery;
    if (post.acf) {
      if(!post.acf.brewery){
        brewery = <span></span>; 
      } else {
        brewery = <div> <span className="acf-style-title">Brewery:</span> <span className="acf-style">{post.acf.brewery}</span></div>;
      }
    } else {
      brewery = <span></span>;
    }

    //TYPES OF BEER ACF
    let beers;
    if (post.acf) {
      if(!post.acf.beers){
        beers = <span></span>;
      } else {
        beers = <div> <span className="acf-style-title">Types of beer:</span> <span className="acf-style">{post.acf.beers}</span></div>;
      }
    } else {
      beers = <span></span>;
    }

    //TYPES OF BEER ACF
    let location;
    if (post.acf) {
      if(!post.acf.location){
        location = <span></span>;
      } else {
        location = <div> <span className="acf-style-title">Location:</span> <span className="acf-style">{post.acf.location}</span></div>;
      }
    } else {
      location = <span></span>;
    }

    //TYPES OF BEER ACF
    let website;
    if (post.acf) {
      if(!post.acf.website){
        website = <span></span>;
      } else {
        website = <div> <span className="acf-style-title">website:</span> <span className="acf-style">{post.acf.website}</span></div>;
      }
    } else {
      website = <span></span>;
    }


    window.scrollTo(0, 0);


    return (

        <div>

            <Col className="back-btn" xs={12} sm={6} md={6}>
              <Link to={`/`}>
                <img className="back-post" src={require('../img/getmeback.png')} alt="back" />
              </Link>
              <Link to={`/`}>
                <p className="take-me-back">take me back</p>
              </Link>
            </Col>
            
            <div className="post-header">
              
              <div className="image-header-box" xs={12} sm={6} md={6}>
                <img className="post-header-imagen card-image" src={imageUrl} alt="post" />
              </div>
              
              <div className="title-header-box" xs={12} sm={6} md={6}>
                <div className="post-box-title">
                  <h1 className="post-header-title">{ post.title ? post.title.rendered : 
                    <p>...</p>}
                  </h1>
                </div>
              </div>
              
            </div>




          <Container>
            <Row>
              <Col className="post-container" xs={12} sm={12} md={12}>

                
                <h1 className="post-details-title">{ post.title ? post.title.rendered : 
                    <div className="loader-box">
                        <img className="loader" src={require('../img/loader.gif')} alt="loader" />
                    </div>}
                </h1>
              
                { post.content && (<p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>) }

                <div className="container-acf">
                  {brewery}
                  {beers}
                  {location}
                  {website}
                </div>

        
              </Col>
            </Row>
          </Container>

          <Footer />

          
        
        </div>

        
      
    );
  }

}

export default PostDetails;
