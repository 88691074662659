import React from 'react';
import ReactGA from 'react-ga';
import './App.css';
import { Route, HashRouter } from 'react-router-dom';
import Root from './components/root';
import PostDetails from './components/postDetails';
import Us from './components/us';



function App() {

  const trackingId = "UA-162784086-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);
  
  //ReactGA.pageview('/');
  //ReactGA.pageview('/post/:id/:fi');

  //ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.pageview(window.location.hash);



  /*ReactGA.set({
    //userId: auth.currentUserId(),
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })*/


  return (
    
    <HashRouter>
      <Route path="/" exact component={Root} />
      <Route path="/post/:id/:fi" exact component={PostDetails} />
      <Route path="/us" exact component={Us} /> 
    </HashRouter>
  );
}

export default App;
