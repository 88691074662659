
/*const appUrl = 'http://sonsofthebrew.com/wp-json/wp/v2/';

export const Data = (
    
    
    `${appUrl}posts?_embed`


  )*/


const appUrl = 'https://afxjlkijqw.sonsofthebrew.com/wp-json/wp/v2/'; 

const Data = {    

    allPosts: `${appUrl}posts?_embed`,
    beers: `${appUrl}posts?_embed&categories=2`,
    breweries: `${appUrl}posts?_embed&categories=3`,
    bars: `${appUrl}posts?_embed&categories=4`,
}

export default Data
  
